import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Typography } from '../utils'

export const TextCard = ({ children, ...rest }) => {
  return <TextContainer {...rest}>{children}</TextContainer>
}
export const TextCardIcon = ({ children }) => {
  return <TextIcon>{children}</TextIcon>
}
export const TextCardHeading = ({ children }) => {
  return <TextHeading variant="h3">{children}</TextHeading>
}
export const TextCardBody = ({ children }) => {
  return (
    <TextBody variant="p" fontWeight="medium">
      {children}
    </TextBody>
  )
}

const TextContainer = styled(motion.div)`
  padding: 1rem 2rem 1rem 1rem;
  background-color: white;
  color: ${({ theme }) => theme.primary};
  border-radius: 1rem;
  width: 100%;
  min-height: 12rem;
  pointer-events: none;
`
const TextHeading = styled(Typography)`
  text-align: center;
  line-height: 150%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TextIcon = styled.span`
  display: flex;
  align-items: center;
`
const TextBody = styled(Typography)`
  line-height: 150%;
  color: ${({ theme }) => theme.color.purple400};
  margin-right: 2rem;
  /* pointer-events: hidden; */
`
