import React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import fallbackImg from '../../images/image.svg'
import { Typography } from '../utils'

const itemMotion = {
  initial: {
    opacity: 0,
    y: -10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 10,
  },
}
export const PreviewItem = ({ imageUrl, name, type, motionItem, custom }) => {
  return (
    <AnimatePresence>
      <Container variants={motionItem} custom={custom}>
        <ImageContainer variants={itemMotion}>
          <Img
            srcSet={imageUrl}
            src={fallbackImg}
            width="40"
            height="40"
            alt={name}
            loading="auto"
          />
        </ImageContainer>
        <Content variants={itemMotion}>
          <Type variant="label">{type}</Type>
          <Title variant="h6">{name}</Title>
        </Content>
      </Container>
    </AnimatePresence>
  )
}

export const PreviewPlaceholder = ({ index, motionItem, layoutId }) => {
  const placeholderMotion = {
    initial: (i) => ({
      opacity: 0.3,
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: i * 0.1,
      },
    }),
    animate: (i) => ({
      opacity: 1,
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: i * 0.1,
      },
    }),
    exit: { opacity: 0 },
  }

  return (
    <AnimatePresence>
      <Container variants={motionItem}>
        <ImageContainer
          placeHolder={true}
          initial="initial"
          animate="animate"
          custom={index}
          variants={placeholderMotion}
        />
        <Content
          placeHolder={true}
          initial="initial"
          animate="animate"
          custom={index + 3}
          variants={placeholderMotion}
        />
      </Container>
    </AnimatePresence>
  )
}

const Container = styled(motion.div)`
  padding: 8px 0;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  border-radius: 8px;
`
const ImageContainer = styled(motion.div)`
  width: 48px;
  height: 48px;
  background-color: ${({ placeHolder }) =>
    placeHolder ? 'rgba(255,255,255,0.3)' : 'white'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  @media (min-width: 1600px) {
    width: 64px;
    height: 64px;
  }
`
const Img = styled.img`
  width: 85%;
  height: 85%;
  object-fit: contain;
`
const Content = styled(motion.div)`
  ${({ placeHolder }) =>
    placeHolder &&
    css`
      width: 280px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      @media (max-width: 424px) {
        width: 55vw;
      }
    `}
`
const Type = styled(Typography)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  @media (min-width: 1600px) {
    font-size: 0.9rem;
  }
`
const Title = styled(Typography)`
  line-height: 125%;
`
