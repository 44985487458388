import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Homepage from '../components/Homepage/Homepage'
import {
  ComponentContext,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
  MetaComponentContext,
  callBuild,
} from '../components/helpers'
import { Seo } from '../components/utils'

export default function IndexPage({ data }) {
  const { posts } = data
  const [contextComponents, setContextComponents] = useState(INITIAL_BUILD)
  const [contextMeta, setContextMeta] = useState(INITIAL_META)

  const postNodes = posts
    ? mapEdgesToNodes(posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  const fetchPreview = async () => {
    const budget =
      Math.round((Math.floor(Math.random() * 2000) + 1000) / 100) * 100
    const { build, buildMetadata } = await callBuild({ budget: budget })

    if (!!build?.length) {
      setContextComponents(build)
      setContextMeta(buildMetadata)
    }
  }

  useEffect(() => {
    fetchPreview()
    const interval = setInterval(() => fetchPreview(), 15000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <ComponentContext.Provider
        value={[contextComponents, setContextComponents]}
      >
        <MetaComponentContext.Provider value={[contextMeta, setContextMeta]}>
          <Seo
            title="ghostbuilder.io - Dein automatischer PC-Konfigurator"
            titleTemplate={' '}
          />
          <Homepage posts={postNodes} />
        </MetaComponentContext.Provider>
      </ComponentContext.Provider>
    </>
  )
}

export const LandingPageQuery = graphql`
  query LandingpageQuery {
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
`

const INITIAL_META = {
  buildID: '92606525',
  timestamp: '2023-02-07 21:59:12.910056',
  buildPrice: '1398.90',
}

const INITIAL_BUILD = [
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=tease-de&amp;loc=https%3A%2F%2Ftease-shop.de%2Fpc-komponenten%2Fprozessoren%2F275171748%2Fintel-core-i5-12400f-2.5-ghz-6-kerne-12-threads&amp;ghaID=2660245&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=57673a4ccfedc6d747b702965f9729b0',
    imageUrl: 'https://gzhls.at/i/02/45/2660245-n0.jpg',
    name: 'Intel Core i5-12400F Tray',
    partInfo: [
      { label: 'manufacturer', value: 'Intel' },
      { label: 'cores', value: '6' },
      { label: 'threads', value: '12' },
      { label: 'baseclock', value: '2,5 GHz' },
      { label: 'boostclock', value: '4,4 GHz' },
      { label: 'socket', value: '1700' },
      { label: 'needsGPU', value: '1' },
      { label: 'overclocking', value: '0' },
    ],
    placeholder: '0',
    price: 152.72,
    provider: 'tease Shop',
    type: 'Prozessor',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=computeruniverse&amp;loc=https%3A%2F%2Fwww.computeruniverse.net%2Fde%2Fp%2F90857903%3Futm_channel%3Dpsm%26utm_source%3Dgeizhals%26utm_campaign%3Dsofort-lieferbar%26utm_medium%3Dkatalog%26utm_content%3Dartikel%26agt%3D288%26acode%3DDSCV-8167&amp;ghaID=2661049&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=7a163951f137b36befdfeca93e55a6dd',
    imageUrl: 'https://gzhls.at/i/10/49/2661049-n0.jpg',
    name: 'ASRock H670 Steel Legend',
    partInfo: [
      { label: 'manufacturer', value: 'ASRock' },
      { label: 'socket', value: '1700' },
      { label: 'chipset', value: 'H670' },
      { label: 'format', value: 'ATX' },
      { label: 'overclocking', value: '0' },
      { label: 'ramtyp', value: 'DDR4' },
      { label: 'm2slots', value: '3' },
      { label: 'pcie40slots', value: '3' },
      { label: 'pcie30slots', value: '0' },
      { label: 'usb2.0', value: '6' },
      { label: 'usb3.2Gen1', value: '6' },
      { label: 'usb3.2Gen2', value: '2' },
      { label: 'sata3', value: '4' },
      { label: 'wifi', value: '0' },
      { label: 'lan1', value: '2,5G' },
      { label: 'lan2', value: '0' },
      { label: 'rgb', value: '1' },
    ],
    placeholder: '0',
    price: 168.66,
    provider: 'computeruniverse.net',
    type: 'Mainboard',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=proshop-de&amp;loc=https%3A%2F%2Fwww.proshop.de%2FGrafikkarte%2FSapphire-Radeon-RX-6800-XT-Nitro-SE-16GB-GDDR6-Grafikkarte%2F2909013%3Futm_source%3Dgeizhals%26utm_medium%3Dcpc%26utm_campaign%3Dpricesite&amp;ghaID=2420873&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=aba495a0e533bc1ac5a72345fa7c18b6',
    imageUrl: 'https://gzhls.at/i/08/73/2420873-n0.jpg',
    name: 'Sapphire Nitro+ Radeon RX 6800 XT SE',
    partInfo: [
      { label: 'manufacturer', value: 'Sapphire' },
      { label: 'gpuManufacturer', value: 'AMD' },
      { label: 'baseclock', value: '1487' },
      { label: 'boostclock', value: '2360' },
      { label: 'length', value: '310' },
      { label: 'pins6', value: '0' },
      { label: 'pins8', value: '2' },
      { label: 'rgb', value: '1' },
    ],
    placeholder: '0',
    price: 736.9,
    provider: 'Proshop.de',
    type: 'Grafikkarte',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=mindfactory&amp;loc=https%3A%2F%2Fwww.mindfactory.de%2Fproduct_info.php%2Finfo%2Fp1369994%2Fpid%2Fgeizhals&amp;ghaID=2303807&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=662b5bb5d36020868f0206231ea82b80',
    imageUrl: 'https://gzhls.at/i/38/07/2303807-n0.jpg',
    name: 'G.Skill RipJaws V schwarz DIMM Kit 16 GB DDR4-3600',
    partInfo: [
      { label: 'manufacturer', value: 'G.Skill' },
      { label: 'capacity', value: '16' },
      { label: 'ddrgen', value: 'DDR4' },
      { label: 'modules', value: '2' },
      { label: 'clock', value: '3600' },
      { label: 'rgb', value: '0' },
    ],
    placeholder: '0',
    price: 46.97,
    provider: 'Mindfactory',
    type: 'Arbeitsspeicher',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=mindfactory&amp;loc=https%3A%2F%2Fwww.mindfactory.de%2Fproduct_info.php%2Finfo%2Fp1394529%2Fpid%2Fgeizhals&amp;ghaID=2465268&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=3539800c53f22a83741a159e0c2a3905',
    imageUrl: 'https://gzhls.at/i/52/68/2465268-n0.jpg',
    name: 'Corsair Force Series MP600 Pro 1 TB',
    partInfo: [
      { label: 'manufacturer', value: 'Corsair' },
      { label: 'capacitySsd', value: '1000' },
      { label: 'read', value: '7000' },
      { label: 'write', value: '5500' },
      { label: 'format', value: 'M.2' },
      { label: 'connection', value: 'PCIe 4.0' },
      { label: 'tbw', value: '700' },
      { label: 'rgb', value: '0' },
    ],
    placeholder: '0',
    price: 108.58,
    provider: 'Mindfactory',
    type: 'Primärer Speicher',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=csv&amp;loc=https%3A%2F%2Fwww.csv-direct.de%2Fartinfo.php%3Fartnr%3DA0180671%26KATEGORIE%3D018%26pva%3Dgeizhals2&amp;ghaID=1675194&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=1b8b96c339f9dc2f6ee449e5933f6c3b',
    imageUrl: 'https://gzhls.at/i/51/94/1675194-n0.jpg',
    name: 'Alpenföhn Brocken 3',
    partInfo: [
      { label: 'manufacturer', value: 'Alpenföhn' },
      { label: 'radiatorSize', value: '0' },
      { label: 'coolerHeight', value: '165' },
      { label: 'socket1151v2', value: '1' },
      { label: 'socket2066', value: '1' },
      { label: 'socketAM4', value: '1' },
      { label: 'socketTR4', value: '0' },
      { label: 'rgb', value: '0' },
    ],
    placeholder: '0',
    price: 42.18,
    provider: 'CSV-Direct.de',
    type: 'Kühler',
  },
  {
    affiliateLink: 'https://www.amazon.de/dp/B07RYN8CF2',
    imageUrl: 'https://m.media-amazon.com/images/I/51quN5iksFL._SL160_.jpg',
    name: 'Corsair RM650',
    partInfo: [
      { label: 'manufacturer', value: 'Corsair' },
      { label: 'power', value: '650' },
      { label: 'pins6plus2', value: '4' },
      { label: 'pins4plus4', value: '2' },
      { label: 'length', value: '160' },
      { label: '80plus', value: 'Gold' },
      { label: 'modular', value: 'Vollmodular' },
    ],
    placeholder: '0',
    price: 88.9,
    provider: 'Amazon',
    type: 'Netzteil',
  },
  {
    affiliateLink:
      'https://geizhals.de/redir.cgi?h=hardwarerat-de&amp;loc=https%3A%2F%2Fhardwarerat.de%2Fhardware%2Fgehaeuse%2Fatx%2F439%2Fantec-nx400-glasfenster-schwarz%3FsPartner%3DGH&amp;ghaID=2111223&amp;ghxID=0A571AD0E79911EABED2560A353BA95E&amp;key=03c8e2e80e2dfc01fe60920c99efa39e',
    imageUrl: 'https://gzhls.at/i/12/23/2111223-n0.jpg',
    name: 'Antec NX400',
    partInfo: [
      { label: 'manufacturer', value: 'Antec' },
      { label: 'color', value: 'schwarz' },
      { label: 'sidePanel', value: 'Glas' },
      { label: 'gpuLengthMax', value: '330' },
      { label: 'coolerHeightMax', value: '170' },
      { label: 'drives2,5', value: '4' },
      { label: 'drives3,5', value: '2' },
      { label: 'radiatorFront120', value: '1' },
      { label: 'radiatorFront140', value: '0' },
      { label: 'radiatorFront240', value: '1' },
      { label: 'radiatorFront280', value: '0' },
      { label: 'radiatorFront360', value: '1' },
      { label: 'radiatorUp120', value: '1' },
      { label: 'radiatorUp140', value: '0' },
      { label: 'radiatorUp240', value: '1' },
      { label: 'radiatorUp280', value: '0' },
      { label: 'radiatorUp360', value: '0' },
      { label: 'radiatorBack120', value: '0' },
      { label: 'radiatorBack140', value: '1' },
    ],
    placeholder: '0',
    price: 53.99,
    provider: 'HardwareRat',
    type: 'Gehäuse',
  },
]
